import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import animateScrollTo from "animated-scroll-to";
import { motion } from "framer-motion";

import { GridContainer } from "../GridContainer";
import { MotionArrow } from "../Motion";
import { splitText, getLeadMaxLetters } from "../splitTextService";
import { useWindowWidth } from "@react-hook/window-size";

interface Props {
  title: string;
  text?: string;
  hideScroll?: boolean;
  links?: React.ReactNode[];
}

const appearDelay = 1.8;

const leadVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: appearDelay,
    },
  },
};

const textVariants = {
  hidden: {
    y: 30,
  },
  visible: {
    y: 0,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 1.8,
      delay: appearDelay,
    },
  },
};

const linksVariants = {
  hidden: {
    y: 50,
  },
  visible: {
    y: 0,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 1.8,
      delay: appearDelay,
    },
  },
};

export const Lead: React.FC<Props> = ({
  title,
  text,
  hideScroll,
  links = [
    <Link
      to="/realizace"
      key="realizace"
      className="lead-link-first link-hover hover-orange"
    >
      Naše realizace
    </Link>,
    <Link to="/poptavka" key="poptavka" className="link-hover hover-orange">
      Poptávka
    </Link>,
  ],
}) => {
  const [maxLetters, setMaxLetters] = useState<number>(30);
  const width = useWindowWidth();

  useEffect(() => {
    setMaxLetters(getLeadMaxLetters(width));
  }, [width]);

  return (
    <GridContainer>
      <div className="lead">
        <h1 className="lead-title">
          {splitText(title, maxLetters).map((titleLine) => {
            return (
              <div className="appear-wrapper-h1" key={titleLine}>
                <span className="appear-content-h1">{titleLine}</span>
              </div>
            );
          })}
        </h1>
        <motion.div
          className="lead-text"
          initial="hidden"
          animate="visible"
          variants={leadVariants}
        >
          {text && (
            <motion.p
              variants={textVariants}
              animate="visible"
              initial="hidden"
            >
              {text}
            </motion.p>
          )}
          <div className="lead-links">
            <motion.div
              variants={linksVariants}
              animate="visible"
              initial="hidden"
            >
              {links}
            </motion.div>
            {!hideScroll && (
              <div
                className="lead-scroll"
                onClick={() => {
                  const el = document.querySelector("#scroll-to");
                  if (el) {
                    animateScrollTo(el);
                  }
                }}
              >
                <MotionArrow />
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </GridContainer>
  );
};
