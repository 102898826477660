import React from "react";
import { Lead } from "../Lead";
import image from "../../images/demand.jpg";
import profileImage from "../../images/demand-profile.jpg";
import { GridSpace } from "../Spacing/GridSpace";
import { TextImage, TextImageImage } from "../TextImage";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";
import { TextImageTextDemand } from "../sections/TextImageTextDemand";
import { TextImageTextDemand2 } from "../sections/TextImageTextDemand2";

export const Demand: React.FC = () => {
  return (
    <Page
      title="Poptávka"
      description="Abychom porozuměli Vašemu projektu, musíme o něm vědět víc. Popište nám Vaši vizi, požadavky i tajná přání."
    >
      <AfterNavbar />
      <AppearSection>
        <Lead
          title="Máte zájem o naše služby?"
          text="Abychom porozuměli Vašemu projektu, musíme o něm vědět víc. Popište nám Vaši vizi, požadavky i tajná přání, pošlete nám stavební dokumentaci, údaje o pozemku a přibližnou dobu realizace. My si projekt prostudujeme a domluvíme si s Vámi osobní schůzku."
          links={[
            <a
              key="email"
              className="lead-link-first link-hover hover-orange"
              href="mailto:info@fastplay.cz"
            >
              info@fastplay.cz
            </a>,
            <a
              key="phone"
              className="link-hover hover-orange"
              href="tel:+420702064615"
            >
              +420 702 064 615
            </a>,
          ]}
        />
      </AppearSection>
      <ScrollTo />
      <AppearSection>
        <TextImage className="after-lead">
          <TextImageImage src={image} />
          <TextImageTextDemand />
        </TextImage>
      </AppearSection>
      <GridSpace />
      <AppearSection>
        <TextImage>
          <TextImageTextDemand2 />
          <TextImageImage src={profileImage} />
        </TextImage>
      </AppearSection>
      <GridSpace />
    </Page>
  );
};
