import React from "react";

import { Lead } from "../Lead";
import profileImage from "../../images/demand-profile.jpg";
import { GridSpace } from "../Spacing/GridSpace";
import { TextImage, TextImageImage } from "../TextImage";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";
import { ContactBoxList } from "../sections/ContactBoxList";
import { TextImageTextContact } from "../sections/TextImageTextContact";
import { GrayBackgroundSmallSpacing } from "../GrayBackgroundSmallSpacing";

export const Contact: React.FC = () => {
  return (
    <Page
      title="Kontakt"
      description="Všechny požadavky a přání klienta se snažíme vždy splnit tak, aby byl stoprocentně spokojen, proto ho musíme nejprve poznat."
    >
      <AfterNavbar />
      <AppearSection>
        <Lead
          title="Kontaktujte nás"
          text="Napište nám o svém projektu e-mailem, pošlete poptávku nebo si domluvte osobní schůzku. Adresu naší kanceláře najdete níže."
          links={[
            <a
              key="map"
              className="lead-link-first link-hover hover-orange"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.cz/maps/place/FastPlay+s.r.o./@50.032184,15.800544,17z/data=!3m1!4b1!4m5!3m4!1s0x470dcc673374dd4b:0xde9318675abdd0ee!8m2!3d50.032184!4d15.8027327"
            >
              Navigovat do kanceláře
            </a>,
          ]}
        />
      </AppearSection>
      <div className="after-lead">
        <ScrollTo />
        <AppearSection>
          <GrayBackgroundSmallSpacing>
            <ContactBoxList />
          </GrayBackgroundSmallSpacing>
        </AppearSection>
      </div>
      <GridSpace />
      <AppearSection>
        <TextImage>
          <TextImageTextContact />
          <TextImageImage src={profileImage} />
        </TextImage>
      </AppearSection>
      <GridSpace />
    </Page>
  );
};
