import React from "react";
import { motion } from "framer-motion";

import { ResponsiveImage } from "../ResponsiveImage";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

interface Props {
  src: string;
  alt: string;
  delay?: number;
}

export const MotionReferenceImage: React.FC<Props> = ({
  src,
  alt,
  delay = 1.8,
}) => {
  const imageVariants = {
    hidden: { x: "-100vw" },
    visible: {
      x: ["-100vw", "0vw", "0vw"],
      scale: [1, 1, 1.05],
      transition: {
        delay: delay,
        ease: [0.375, 0.725, 0, 1],
        duration: 1.2,
      },
    },
  };

  const isAppeared = useIsAppearedContext();

  return (
    <div className="container-fluid overflow-hidden">
      <div className="overflow-hidden">
        <motion.div
          whileHover={{
            scale: 1,
          }}
          transition={{
            duration: 0.6,
            ease: [0.375, 0.725, 0, 1],
          }}
          initial="hidden"
          animate={isAppeared ? "visible" : "hidden"}
          variants={imageVariants}
        >
          <ResponsiveImage src={src} alt={alt} />
        </motion.div>
      </div>
    </div>
  );
};
