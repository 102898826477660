import React from "react";
import { Link } from "react-router-dom";
import { Lead } from "../Lead";
import image from "../../images/homepage.jpg";
import { GridSpace } from "../Spacing/GridSpace";
import { GrayBackground } from "../GrayBackground";
import { BoxList, BoxListItem } from "../BoxList";
import { IconLink } from "../Links";
import { Description } from "../Description";
import { ReferencesSlider } from "../ReferencesSlider";
import { GridContainer } from "../GridContainer";
import { GridFluidContainer } from "../GridFluidContainer";
import { useReferenceType } from "../../store/reference/useReferenceType";
import { ReferencesFilter } from "../ReferencesFilter";
import { QuotesSlider } from "../Quote";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";
import { AboutImages } from "../AboutImages";
import { MotionImage } from "../Motion";

export const Homepage: React.FC = () => {
  const type = useReferenceType();

  return (
    <Page
      title="Stavební firma Pardubice – Stavíme rodinné domy v Pardubicích"
      description="Ve stavebnictví podnikáme již více než dvacet let. Našim spokojeným klientům pomáháme s kompletní realizací moderních rodinných domů a rekonstrukcí."
    >
      <AfterNavbar />
      <AppearSection threshold={0}>
        <Lead
          title="Profesionální dodavatel Vaší stavby"
          text="Ve stavebnictví podnikáme již více než dvacet let. Našim klientům
            pomáháme s kompletní realizací moderních rodinných domů,
            průmyslových staveb a rekonstrukcí. Každou naši stavbu realizujeme s 
            ohledem na kvalitu a detail tak, abychom byli na výsledek hrdí."
        />
        <MotionImage src={image} alt="hero-image" />
      </AppearSection>
      <GridSpace />
      <ScrollTo />

      <AppearSection>
        <GrayBackground>
          <BoxList
            title={
              <>
                <span className="description-spacing">Komplexní servis a</span>{" "}
                nabídka stavebních služeb
              </>
            }
            text="Poskytujeme širokou nabídku služeb. Stavíme originální, reprezentativní i typizované rodinné domy, realizujeme také rekonstrukce. Máme také zkušenosti se stavbou průmyslových budov a hal."
            items={[
              <BoxListItem key="rodinne-domy">
                <IconLink
                  iconText="01"
                  text="Rodinné domy"
                  link="/rodinne-domy"
                />
              </BoxListItem>,
              <BoxListItem key="rekonstrukce">
                <IconLink
                  iconText="02"
                  text="Rekonstrukce"
                  link="/rekonstrukce"
                />
              </BoxListItem>,
              <BoxListItem key="prumyslove-stavby">
                <IconLink
                  iconText="03"
                  text="Průmyslové stavby"
                  link="/prumyslove-stavby"
                />
              </BoxListItem>,
            ]}
          />
        </GrayBackground>
      </AppearSection>

      <GridSpace />

      <AppearSection>
        <GridContainer>
          <Description
            title="Naše realizace mluví za nás"
            text="Spolupracujeme s osvědčenými specialisty, kteří jsou Vám k dispozici ve všech fázích realizace projektu. Proto můžeme splnit i neobvyklá přání a náročná zadání. Máme stálý tým zaměstnanců, díky kterým můžeme zaručit vysokou kvalitu služeb a mít neustálý přehled o průběhu stavby."
            link={
              <div className="filter-links filter-links-spaced">
                <ReferencesFilter linkBase="/" />
              </div>
            }
          />
        </GridContainer>
      </AppearSection>

      <ReferencesSlider type={type} />

      <GridSpace />
      <AppearSection>
        <GridContainer className="quotes-section">
          <Description
            title="Co o nás říkají naší spokojení klienti"
            text="Člověk si nestaví dům pro život každý den. Víme, že je důležité
            udržovat se zákazníkem kontakt od prvního setkání po úspěšné
            dokončení stavby. Proto se snažíme o přátelský a otevřený
            přístup k našim klientům, aby pro ně byla stavba příjemnou
            cestou k vlastnímu bydlení."
          />
        </GridContainer>
      </AppearSection>
      <AppearSection>
        <QuotesSlider />
      </AppearSection>

      <GridSpace />
      <GridContainer className="about-section">
        <AppearSection>
          <Description
            title="Jsme sehraný tým, kterému záleží na každém detailu."
            text="Máme stabilní tým profesionálů, který vám je k dispozici ve všech
            fázích realizace vašeho projektu. Náš tým je vždy připraven řešit
            vaše stavební výzvy tak, abychom byli na výsledek pyšní."
            link={
              <Link
                to="/o-firme"
                className="description-button-spacing link-hover hover-orange"
              >
                O firmě
              </Link>
            }
          />
        </AppearSection>
      </GridContainer>
      <GridFluidContainer className="images-spacing">
        <AppearSection>
          <AboutImages />
        </AppearSection>
      </GridFluidContainer>
      <GridSpace />
    </Page>
  );
};
