import React from "react";
import { motion } from "framer-motion";

import { ReactComponent as IconBank } from "../../icons/icon-bank.svg";
import { ReactComponent as IconInvoice } from "../../icons/icon-invoice.svg";
import { ReactComponent as IconLocation } from "../../icons/icon-location.svg";
import { ReactComponent as IconMessage } from "../../icons/icon-message.svg";
import { BoxList, BoxListItem } from "../BoxList";
import { TextBox } from "../TextBox";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

const textVariants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

export const ContactBoxList: React.FC = () => {
  const isAppeared = useIsAppearedContext();
  const cols = "col-lg-3 col-sm-6";

  return (
    <BoxList
      items={[
        <BoxListItem key="najdete" cols={cols}>
          <TextBox
            icon={<IconLocation />}
            title="Kde nás najdete"
            className="text-box-high-lines"
          >
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
            >
              FastPlay s.r.o.
              <br />
              Dašická 1796/8
              <br />
              53003 Pardubice
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
              className="text-small"
            >
              Doporučujeme parkovat u Dašická 1759, 530 03
            </motion.p>
          </TextBox>
        </BoxListItem>,
        <BoxListItem key="fakturace" cols={cols}>
          <TextBox
            icon={<IconInvoice />}
            title="Fakturační údaje"
            className="text-box-high-lines"
          >
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
            >
              FastPlay s.r.o.
              <br />
              IČ: 28551028
              <br />
              DIČ: CZ28551028
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
              className="text-small"
            >
              Společnost je zapsaná v OR vedeném u Městského soudu v Hradci
              Králové oddíl C 26935/KSHK.
            </motion.p>
          </TextBox>
        </BoxListItem>,
        <BoxListItem key="banka" cols={cols}>
          <TextBox
            icon={<IconBank />}
            title="Bankovní spojení"
            className="text-box-high-lines"
          >
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
            >
              230464505/0300
            </motion.p>
          </TextBox>
        </BoxListItem>,
        <BoxListItem key="otazky" cols={cols}>
          <TextBox
            icon={<IconMessage />}
            title="Máte otázky?"
            className="text-box-high-lines"
          >
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
            >
              Petr Čejka
              <br />
              <a href="tel:+420702064615" className="link-hover hover-orange">
                +420 702 064 615
              </a>
              <br />
              <a
                href="mailto:info@fastplay.cz"
                className="link-hover hover-orange"
              >
                info@fastplay.cz
              </a>
            </motion.p>
          </TextBox>
        </BoxListItem>,
      ]}
    />
  );
};
