import React from "react";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../context/IsAppearedContext";

const lineVariants = {
  hidden: {
    width: "0",
  },
  visible: {
    width: "100%",
    transition: {
      delay: 1,
      duration: 0.8,
      ease: [0.375, 0.725, 0, 1],
    },
  },
};

export const SliderPagination: React.FC = () => {
  const isAppeared = useIsAppearedContext();
  return (
    <motion.div
      initial="hidden"
      animate={isAppeared ? "visible" : "hidden"}
      variants={lineVariants}
      className="swiper-pagination"
    ></motion.div>
  );
};
