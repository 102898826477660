import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

import { ReactComponent as Quotes } from "../../icons/icon_quotes.svg";
import { splitText, getLongQuoteMaxLetters } from "../splitTextService";

import { useIsAppearedContext } from "../../context/IsAppearedContext";
import { textVariants, quotesIconVariants } from "./quoteMotion";

interface Props {
  author: string;
  reference: string;
  quote: string;
}

export const QuoteLong: React.FC<Props> = ({ author, reference, quote }) => {
  const [maxLetters, setMaxLetters] = useState<number>(30);
  const width = useWindowWidth();
  const isAppeared = useIsAppearedContext();

  useEffect(() => {
    setMaxLetters(getLongQuoteMaxLetters(width));
  }, [width]);

  return (
    <div className="row quote-long">
      <div className="col-lg-4 d-flex order-lg-1 order-2 quote-refs">
        <div>
          <div className="overflow-hidden appear-wrapper-author">
            <motion.div
              variants={textVariants}
              animate={isAppeared ? "visible" : "hidden"}
              initial="hidden"
              className="quote-author appear-content-author"
            >
              {author}
            </motion.div>
          </div>
          <div className="overflow-hidden appear-wrapper-reference">
            <motion.div
              variants={textVariants}
              animate={isAppeared ? "visible" : "hidden"}
              initial="hidden"
              className="quote-reference appear-content-reference"
            >
              {reference}
            </motion.div>
          </div>
        </div>
      </div>
      <div className="col-lg-8 order-lg-2 order-1">
        <div className="overflow-hidden quotes-spacing">
          <motion.div
            variants={quotesIconVariants}
            animate={isAppeared ? "visible" : "hidden"}
            initial="hidden"
          >
            <Quotes />
          </motion.div>
        </div>

        <h2>
          {splitText(quote, maxLetters).map((quoteLine) => {
            return (
              <div
                className="appear-wrapper-h2 overflow-hidden"
                key={quoteLine}
              >
                <motion.div
                  variants={textVariants}
                  animate={isAppeared ? "visible" : "hidden"}
                  initial="hidden"
                  className="appear-content-h2"
                >
                  {quoteLine}
                </motion.div>
              </div>
            );
          })}
        </h2>
      </div>
    </div>
  );
};
