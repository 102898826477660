import { ReferenceType } from "../../store/reference/types";

interface FilterMapping {
  [key: string]: ReferenceType;
}

const filterMapping: FilterMapping = {
  "rodinne-domy": ReferenceType.Family,
  rekonstrukce: ReferenceType.Reconstruction,
  "prumyslove-stavby": ReferenceType.Industry,
};

export function getReferenceTypeFromFilter(
  filter?: string | null
): ReferenceType | undefined {
  if (!filter) {
    return ReferenceType.Family;
  }

  return filterMapping[filter];
}
