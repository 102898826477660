import React from "react";

export interface ReferenceDataProps {
  title: string;
  value: string;
}

export const ReferenceDataItem: React.FC<ReferenceDataProps> = ({
  title,
  value,
}) => {
  return (
    <div className="appear-wrapper-property">
      <div className="property-item-wrapper appear-content-property">
        <div className="property-item">
          <div>{value}</div>
          <div>{title}</div>
        </div>
      </div>
    </div>
  );
};
