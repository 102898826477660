import React from "react";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const GridContainer: React.FC<Props> = ({ className, children }) => {
  return <div className={classNames("container", className)}>{children}</div>;
};
