import React from "react";

interface Props {
  src: string;
  alt: string;
}

export const ResponsiveImage: React.FC<Props> = ({ src, alt }) => {
  return (
    <div
      className="responsive-image"
      style={{ backgroundImage: `url(${src})` }}
    >
      <img src={src} alt={alt} />
    </div>
  );
};
