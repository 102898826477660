import React from "react";

import classNames from "classnames";
import { ReferenceType } from "../store/reference/types";
import { useReferenceType } from "../store/reference/useReferenceType";
import { Link } from "react-router-dom";

interface Props {
  linkBase: string;
}

export const ReferencesFilter: React.FC<Props> = ({ linkBase }) => {
  const type = useReferenceType();

  return (
    <div className="position-relative d-flex">
      <div className="filter-links-title">Filtr:</div>
      <div className="filter-links-links">
        <div className="d-flex">
          <div className="filter-link">
            <Link
              className={classNames("link-hover", "hover-orange", {
                active: type === ReferenceType.Family,
              })}
              to={`${linkBase}?filter=rodinne-domy`}
            >
              Rodinné domy
            </Link>
          </div>

          <div className="filter-link">
            <Link
              className={classNames("link-hover", "hover-orange", {
                active: type === ReferenceType.Reconstruction,
              })}
              to={`${linkBase}?filter=rekonstrukce`}
            >
              Rekonstrukce
            </Link>
          </div>

          <div className="filter-link">
            <Link
              className={classNames("link-hover", "hover-orange", {
                active: type === ReferenceType.Industry,
              })}
              to={`${linkBase}?filter=prumyslove-stavby`}
            >
              Průmyslové stavby
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
