import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { motion, useViewportScroll } from "framer-motion";

import { ToggleNavbarButton } from "./ToggleNavbarButton";
import { Logo } from "./Logo";
import { Link, useLocation } from "react-router-dom";

const headerVariants = {
  hidden: {
    y: -137,
  },
  visible: {
    y: 0,
    transition: {
      duration: 1.2,
      delay: 0.3,
      ease: [0.375, 0.725, 0, 1],
    },
  },
};

export const Navbar: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { pathname } = useLocation();
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    setIsCollapsed(true);
  }, [pathname]);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    scrollY.onChange((v) => {
      if (v > 0) {
        setIsScrolled(true);
        return;
      }
      setIsScrolled(false);
    });
  }, [scrollY]);

  const navbarClass = classNames({
    "navbar-collapse": true,
    collapsed: isCollapsed,
  });
  return (
    <motion.header variants={headerVariants} initial="hidden" animate="visible">
      <div className="container">
        <nav className={classNames("navbar", { "navbar-compact": isScrolled })}>
          <div>
            <Logo />
            <span className="logo-subtitle">
              Profesionální dodavatel Vaší stavby
            </span>
          </div>
          <ToggleNavbarButton
            isCollapsed={isCollapsed}
            toggleNavbar={() => {
              setIsCollapsed(!isCollapsed);
            }}
          />

          <div className={navbarClass}>
            <ul className="navbar-nav">
              <li className="nav-item appear-wrapper-nav-link">
                <Link
                  className="nav-link appear-content-nav-item"
                  to="/realizace"
                  onClick={() => {
                    setIsCollapsed(true);
                  }}
                >
                  <span className="nav-hover">Realizace</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/o-firme"
                  onClick={() => {
                    setIsCollapsed(true);
                  }}
                >
                  <span className="nav-hover">O firmě</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/poptavka"
                  onClick={() => {
                    setIsCollapsed(true);
                  }}
                >
                  <span className="nav-hover">Poptávka</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/kontakt"
                  onClick={() => {
                    setIsCollapsed(true);
                  }}
                >
                  <span className="nav-hover">Kontakt</span>
                </Link>
              </li>
              <li className="nav-item phone-link">
                <a className="nav-link" href="tel:+420702064615">
                  <span className="nav-hover">+420 702 064 615</span>
                </a>
              </li>
            </ul>
            <div className="close-menu-wrapper appear-wrapper-nav-link">
              <button
                onClick={() => {
                  setIsCollapsed(true);
                }}
                className="close-menu-button appear-content-nav-link"
              >
                Zavřít menu
              </button>
            </div>
          </div>
        </nav>
      </div>
    </motion.header>
  );
};
