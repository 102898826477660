import React from "react";
import { Lead } from "../Lead";
import image from "../../images/about-us.jpg";

import { GridSpace } from "../Spacing/GridSpace";
import { GridContainer } from "../GridContainer";
import { Description } from "../Description";
import { BoxList, BoxListItem } from "../BoxList";
import { TextBox } from "../TextBox";
import { Team } from "../Team";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";
import { MotionImage } from "../Motion";
import { QuoteGrayBackground } from "../Quote";
import { QuoteLong } from "../Quote";

export const AboutUs: React.FC = () => {
  return (
    <Page
      title="O Firmě – Na naše stavby chceme být hrdí"
      description="Soustředíme se na kvalitu, ne na kvantitu, do každé realizace dáváme sto procent tak, abychom mohli být na finální výsledek náležitě hrdí."
    >
      <AfterNavbar />
      <AppearSection threshold={0}>
        <Lead
          title="Na naše stavby chceme být hrdí."
          text="Soustředíme se na kvalitu, ne na kvantitu, do každé realizace dáváme sto procent tak, abychom mohli být na finální výsledek náležitě hrdí. Ke klientovi vždy přistupujeme individuálně a náš tým dělá maximum pro to, abyste na stavbu s námi vzpomínali v dobrém."
        />
        <div className="container-fluid after-lead">
          <MotionImage src={image} alt="O nás" />
        </div>
      </AppearSection>
      <ScrollTo />
      <GridSpace />
      <GridContainer>
        <AppearSection>
          <BoxList
            title="Kvalita, smysl pro detail a osobní přístup je pro nás prioritou"
            text="Máme rádi naši práci a záleží nám na tom, aby měl náš klient na stavbu s námi ty nejlepší vzpomínky."
            items={[
              <BoxListItem key="klientyZname">
                <TextBox
                  title="Naše klienty dobře známe"
                  text="Základem úspěšné spolupráce je dobrá komunikace mezi námi a klientem. Každou stavbu začínáme osobní schůzkou, abychom pochopili potřeby našeho klienta. Během stavby ho průběžně informujeme a konzultujeme případné změny tak, aby byl maximálně spokojen."
                />
              </BoxListItem>,
              <BoxListItem key="muzemeRucit">
                <TextBox
                  title="Za svou práci můžeme ručit"
                  text="Máme dlouholeté zkušenosti v oboru, vlastní tým kvalitních pracovníků a spolupracujeme i se specialisty z různých oborů. Všechny naše domy stavíme z kvalitních a trvanlivých materiálů – bez kompromisů."
                />
              </BoxListItem>,
              <BoxListItem key="duvera">
                <TextBox
                  title="Vzájemná důvěra"
                  text="Vzájemná důvěra je pro nás na prvním místě. Ve stavebnictví se pohybujeme už přes dvacet let a o naše reference se můžete opřít. Jsme profesionálové. Záleží nám na tom, aby naše práce byla kvalitně provedená a Vy jste od nás převzali dům, jaký jste si vysnili. A my zase spoléháme na Vás. Vzájemná důvěra je pro nás cesta, jak si stavbu můžeme společně užít."
                />
              </BoxListItem>,
            ]}
          />
        </AppearSection>
      </GridContainer>
      <GridSpace />
      <GridContainer>
        <AppearSection>
          <Description
            title="Máme stabilní tým profesionálů"
            text="Který vám je k dispozici ve všech fázích realizace vašeho projektu. Náš tým je vždy připraven řešit vaše stavební výzvy tak, abychom byli na výsledek hrdí."
          />
        </AppearSection>
      </GridContainer>
      <GridContainer>
        <AppearSection>
          <Team />
        </AppearSection>
      </GridContainer>
      <GridSpace />
      <GridContainer>
        <AppearSection>
          <Description
            title="Na každém projektu nám záleží."
            text="Každý rok realizujeme jen tolik staveb, abychom Vám mohli zaručit 100% kvalitu."
          />
        </AppearSection>
      </GridContainer>
      <AppearSection>
        <QuoteGrayBackground>
          <QuoteLong
            author="Petr Čejka"
            quote='Než jsem založil svojí firmu, byl jsem zaměstnán v několika stavebních firmách. Vždy mi vadila složitá organizační struktura, kdy přání zákazníka muselo projít od jednatele, přes výrobního ředitele, vedoucího střediska, stavbyvedoucího, mistra atd. Během zdlouhavého procesu se mnohdy z "černé" stala "bílá", což vedlo k nespokojenosti zákazníků. Proto jsem se rozhodl založit firmu bez střední úrovně řízení. Vše s klientem konzultuji osobně, nejen před podpisem smlouvy o dílo, ale i v celém průběhu stavby až do zdárné kolaudace. Věřím, že tento způsob komunikace zjednoduší stavbu Vaší vysněné nemovitosti a bude Vás bavit stejně tak, jako už léta baví mě.'
            reference="Jednatel firmy a zakladatel Fastplay"
          />
        </QuoteGrayBackground>
      </AppearSection>

      <GridSpace />
    </Page>
  );
};
