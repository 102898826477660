import { Reference } from "./types";

export function getNext(
  reference?: Reference,
  references: Reference[] = []
): Reference | undefined {
  if (!reference) {
    return;
  }

  const index = references.findIndex((ref) => ref.id === reference.id);

  if (index === references.length - 1) {
    return references[0];
  }

  return references[index + 1];
}

export function getPrev(
  reference?: Reference,
  references: Reference[] = []
): Reference | undefined {
  if (!reference) {
    return;
  }

  const index = references.findIndex((ref) => ref.id === reference.id);

  if (index === 0) {
    return references[references.length - 1];
  }

  return references[index - 1];
}
