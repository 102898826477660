import React from "react";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

const pathVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 0.6,
    },
  },
};

export const MotionArrowRight: React.FC = () => {
  const isAppeared = useIsAppearedContext();

  return (
    <svg
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      version="1.1"
      className="arrow-button-next"
    >
      <title>Arrow</title>
      <g
        id="01.-Layouts-Web-(FastPlay)-[Ready]"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="arrow-right"
          transform="translate(-131.000000, -276.000000)"
          stroke="#151B22"
        >
          <g id="Descritpion-Name" transform="translate(73.000000, 277.000000)">
            <g
              id="Arrow"
              transform="translate(82.000000, 23.000000) rotate(-90.000000) translate(-82.000000, -23.000000) translate(59.000000, 0.000000)"
            >
              <g
                id="Group-8"
                transform="translate(22.500000, 22.500000) scale(-1, 1) rotate(-270.000000) translate(-22.500000, -22.500000) translate(10.000000, 12.000000)"
              >
                <g id="Group-7" transform="translate(-0.000000, 0.000000)">
                  <motion.path
                    d="M 5.46750145e-14,10.5300911 L 24.4138765,10.375"
                    strokeWidth={1}
                    fill="none"
                    variants={pathVariants}
                    initial="hidden"
                    animate={isAppeared ? "visible" : "hidden"}
                  ></motion.path>
                  <motion.path
                    d="M 14,-2.02473455e-13 L 25,10.5 L 14,21"
                    strokeWidth={1}
                    fill="none"
                    variants={pathVariants}
                    initial="hidden"
                    animate={isAppeared ? "visible" : "hidden"}
                  ></motion.path>
                </g>
              </g>
              <motion.path
                d="
              M 22, 22
              m -22, 0
              a 22,22 0 1,0 44,0
              a 22,22 0 1,0 -44,0
              "
                strokeWidth={1}
                fill="none"
                variants={pathVariants}
                initial="hidden"
                animate={isAppeared ? "visible" : "hidden"}
              ></motion.path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
