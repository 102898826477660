import React from "react";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const GridFluidContainer: React.FC<Props> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames("container-fluid", className)}>{children}</div>
  );
};
