import React from "react";
import { useIsAppearedContext } from "../context/IsAppearedContext";
import { motion } from "framer-motion";

const textVariants = {
  hidden: {
    y: 5,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.6,
    },
  },
};
const underlineVariants = {
  hidden: {
    width: 0,
  },
  visible: {
    width: "100%",
    backgroundColor: "#d1d5dc",
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};
interface Props {
  step: string;
  title: string;
  text: string;
}

export const TimelineItem: React.FC<Props> = ({ step, title, text }) => {
  const isAppeared = useIsAppearedContext();

  return (
    <>
      <motion.div
        variants={textVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        className="timeline-item"
      >
        <div className="timeline-item-step">{step}</div>
        <div className="timeline-item-texts">
          <h3>{title}</h3>
          <p className="timeline-item-description">{text}</p>
        </div>
      </motion.div>
      <motion.div
        style={{ backgroundColor: "transparent", height: 1 }}
        variants={underlineVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      ></motion.div>
    </>
  );
};
