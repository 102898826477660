import React from "react";
import { GridSpace } from "../Spacing/GridSpace";
import { GrayBackground } from "../GrayBackground";
import { ExternalLink } from "../Links";
import { BoxList, BoxListItem } from "../BoxList";
import { TextBox } from "../TextBox";
import { ReactComponent as IconCalendar } from "../../icons/icon-calendar.svg";
import { ReactComponent as IconRepeat } from "../../icons/icon-repeat.svg";
import { ReactComponent as IconWindow } from "../../icons/icon-window.svg";
import { ReactComponent as IconChat } from "../../icons/icon-chat.svg";
import { ReactComponent as IconHome } from "../../icons/icon-home.svg";
import { ReactComponent as IconLock } from "../../icons/icon-lock.svg";
import { Description } from "../Description";
import { Link } from "react-router-dom";
import { TimelineItem } from "../TimelineItem";
import { GridContainer } from "../GridContainer";
import { AppearSection } from "./AppearSection";

export const ProcessInfoSection: React.FC = () => {
  return (
    <>
      <GridSpace />
      <AppearSection>
        <GrayBackground>
          <BoxList
            title="Spolupracujeme s 
            prověřenými dodavateli"
            text="Máme za sebou léta praxe a zkušeností, a proto Vám rádi poradíme s výběrem dodavatelů materiálů, oken, dveří a dalších prvků pro Váš dům."
            items={[
              <BoxListItem key="okna">
                <ExternalLink
                  text="Okna"
                  link="https://www.dafe.cz"
                  linkText="dafe.cz"
                />
              </BoxListItem>,
              <BoxListItem key="zdravotne-technické-instalace">
                <ExternalLink
                  text="Zdravotně technické instalace"
                  link="http://bwvotop.cz/"
                  linkText="bwvotop.cz"
                />
              </BoxListItem>,
              <BoxListItem key="slaboproud-zabezpeceni">
                <ExternalLink
                  text="Slaboproud a zabezpečení"
                  link="http://www.daltech.cz"
                  linkText="daltech.cz"
                />
              </BoxListItem>,
              <BoxListItem key="strechy">
                <ExternalLink
                  text="Střechy"
                  link="https://www.dachdecker.cz"
                  linkText="dachdecker.cz"
                />
              </BoxListItem>,
              <BoxListItem key="stavebniny">
                <ExternalLink
                  text="Stavebniny"
                  link="https://www.stavmat.cz"
                  linkText="stavmat.cz"
                />
              </BoxListItem>,
              <BoxListItem key="sanita">
                <ExternalLink
                  text="Sanita"
                  link="https://www.maro.cz"
                  linkText="maro.cz"
                />
              </BoxListItem>,
            ]}
          />
        </GrayBackground>
      </AppearSection>
      <GridSpace />
      <AppearSection>
        <GrayBackground>
          <BoxList
            title="Máme smysl pro detail"
            text="Zastáveme pravidlo, že detail tvoří celek, proto nic nepřehlédneme."
            items={[
              <BoxListItem key="calendar">
                <TextBox
                  icon={<IconCalendar />}
                  title="Transparentní proces"
                  text="Snažíme se, abyste vždy věděli, v jaké fázi se stavba nachází a jaké kroky budou následovat."
                />
              </BoxListItem>,
              <BoxListItem key="repeat">
                <TextBox
                  icon={<IconRepeat />}
                  title="Kompletní servis"
                  text="Stavíme domy na klíč. Od hrubé stavby po nejdrobnější detaily."
                />
              </BoxListItem>,
              <BoxListItem key="window">
                <TextBox
                  icon={<IconWindow />}
                  title="Kontakty na dodavatele"
                  text="Zajistíme Vám nejlepší cenu za dodávky stavebních materiálů, oken, dveří a dalších prvků."
                />
              </BoxListItem>,
              <BoxListItem key="chat">
                <TextBox
                  icon={<IconChat />}
                  title="Spolupráce s architektem"
                  text="Setkáváme s architektem a konzultujeme s ním proces stavby Vašeho domu včetně všech nejmenších detailů."
                />
              </BoxListItem>,
              <BoxListItem key="home">
                <TextBox
                  icon={<IconHome />}
                  title="Pravidelné kontrolní dny"
                  text="Po domluvě s klientem si stanovíme pravidelné kontrolní dny, během kterých procházíme stavbu a kontrolujeme průběh všech prací."
                />
              </BoxListItem>,
              <BoxListItem key="lock">
                <TextBox
                  icon={<IconLock />}
                  title="Zabezpečení stavby"
                  text="Vždy se snažíme Vaši stavbu zabezpečit proti vniknutí nepovolaných osob."
                />
              </BoxListItem>,
            ]}
          />
        </GrayBackground>
      </AppearSection>
      <GridSpace />
      <GridContainer>
        <AppearSection>
          <Description
            title="Jakým způsobem pracujeme?"
            text="Toto je ve zkratce proces, jakým postupujeme při každém projektu."
            link={
              <Link to="/kontakt" className="link-hover hover-orange">
                Kontaktujte nás
              </Link>
            }
          />
          <div className="timeline">
            <TimelineItem
              step="01"
              title="Osobní schůzka"
              text="Společně si projdeme všechny stavební podklady, případné výzvy a netradiční řešení a Vaše dotazy."
            />
            <TimelineItem
              step="02"
              title="Kalkulace ceny stavby"
              text="Na základě schůzky Vám zpracujeme kompletní položkový rozpočet s finální cenou za stavbu. "
            />
            <TimelineItem
              step="03"
              title="Podpis smlouvy"
              text="Po odsouhlasení rozpočtu připravíme harmonogram stavby a podepíšeme smlouvu o dílo."
            />
            <TimelineItem
              step="04"
              title="Realizace"
              text="Realizace stavby probíhá podle připraveného a odsouhlaseného harmonogramu stavby. Od zahájení po kolaudaci."
            />
          </div>
        </AppearSection>
      </GridContainer>
      <GridSpace />
    </>
  );
};
