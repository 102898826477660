import React, { useState, useEffect } from "react";
import classNames from "classnames";
import useIsInViewport from "use-is-in-viewport";
import { IsAppearedContext } from "../../context/IsAppearedContext";

interface Props {
  threshold?: number;
}

export const AppearSection: React.FC<Props> = ({
  children,
  threshold = 20,
}) => {
  const [isAppeared, setIsAppeared] = useState(false);

  const [isInViewport, targetRef] = useIsInViewport({ threshold });

  useEffect(() => {
    if (isInViewport) {
      setIsAppeared(true);
      return;
    }
  }, [isInViewport]);

  return (
    <IsAppearedContext.Provider value={isAppeared}>
      <div
        ref={targetRef}
        className={classNames("appear-section", {
          "appear-section-appeared": isAppeared,
        })}
      >
        {children}
      </div>
    </IsAppearedContext.Provider>
  );
};
