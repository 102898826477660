import React, { useState } from "react";
import SwiperCore, {
  SwiperOptions,
  EffectFade,
  Autoplay,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Quote } from "./Quote";
import { AppearSection } from "../sections/AppearSection";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../../context/IsAppearedContext";
import { SliderPagination } from "../SliderPagination";

SwiperCore.use([EffectFade, Autoplay, Pagination]);

const backgroundVariants = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: "0",
    transition: {
      delay: 0.2,
      duration: 0.5,
      ease: [0.375, 0.725, 0, 1],
    },
  },
};

const params: SwiperOptions = {
  spaceBetween: 0,
  slidesPerView: 1,
  loop: true,
  pagination: {
    clickable: false,
    type: "progressbar",
    el: ".swiper-pagination",
  },
  autoplay: {
    delay: 5000,
  },
  effect: "fade",
};

const quotesData = [
  {
    author: "Jan Rychnovský",
    reference: "RD Štolbova",
    quote:
      "Náš dům byl jedna velká výzva, od základů, které byly 1,5 metru pod hladinou spodní vody, až po dokončení všech detailů z materiálů jako je travertin, onyx nebo rula. Firma FastPlay si skvěle poradila s každým detailem a výsledek dopadl nad očekávání - jsme nadšeni!",
  },
  {
    author: "Pavel Halbrštát",
    reference: "RD Srch",
    quote:
      "Na radu našich známých jsme od začátku stavby na firmu tlačili a drželi ji pod tlakem. Později jsme zjistili, že provedené práce jsou v nadstandardní kvalitě i bez tlaku a druhou polovinu stavby jsme si Vážně užili. Děkujeme. ",
  },
  {
    author: "Ing. Lukáš Lejsek",
    reference: "RD Pardubičky 1",
    quote:
      "Většina lidí si myslí, že stavba domu je stres. Moje zkušenosti svědčí o opaku! Realizace našeho domu se společností FastPlay mě bavila a každá návštěva stavby mě velmi těšila.",
  },
  {
    author: "Jan Chyba",
    reference: "RD Spojil",
    quote:
      "Ve výběrovém řízení jsme dali přednost konkurenci, avšak ve fázi nedokončené hrubé stavby jsme z důvodu fatálních chyb zhotovitele požádali FastPlay, aby nám pomohla dům dokončit. Dnes spokojeně bydlíme již 7 let a realizujeme společně další 2 projekty.",
  },
];

export const QuotesSlider: React.FC = () => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const isAppeared = useIsAppearedContext();

  return (
    <div className="overflow-hidden quotes-slider container-fluid position-relative">
      <motion.div
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        variants={backgroundVariants}
        className=" bg-gray section-spacing"
      >
        <div className="container">
          <div
            className="quote-swiper-click-catch"
            onClick={() => {
              swiper?.slideNext();
            }}
          ></div>
          <AppearSection>
            <Swiper {...params} onSwiper={setSwiper}>
              {quotesData.map((quoteData) => {
                return (
                  <SwiperSlide key={quoteData.quote}>
                    <Quote {...quoteData} />
                  </SwiperSlide>
                );
              })}

              <SliderPagination />
            </Swiper>
          </AppearSection>
        </div>
      </motion.div>
    </div>
  );
};
