export function splitText(quote: string, maxLetters: number): string[] {
  const reg = new RegExp(`.{1,${maxLetters}}(\\s|$)`, "g");
  const quoteArray = quote.match(reg);
  return quoteArray as Array<string>;
}

export function getQuoteMaxLetters(width: number): number {
  if (width >= 1440) {
    return 38;
  }
  if (width >= 1200) {
    return 32;
  }
  if (width >= 768) {
    return 30;
  }
  return 23;
}

export function getLongQuoteMaxLetters(width: number): number {
  if (width >= 1440) {
    return 57;
  }
  if (width >= 1200) {
    return 47;
  }
  if (width >= 860) {
    return 45;
  }
  if (width >= 768) {
    return 40;
  }
  if (width >= 525) {
    return 38;
  }
  if (width >= 400) {
    return 30;
  }
  return 23;
}

export function getLeadMaxLetters(width: number): number {
  if (width >= 1440) {
    return 40;
  }
  if (width >= 1200) {
    return 40;
  }
  if (width >= 768) {
    return 25;
  }
  if (width >= 615) {
    return 35;
  }
  if (width >= 381) {
    return 25;
  }
  return 20;
}
