import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

import { ReactComponent as ArrowRight } from "../../icons/arrow-right-orange.svg";

const pathVariants = {
  hidden: {
    pathLength: 0,
    fill: "none",
    stroke: "transparent",
  },
  visible: {
    pathLength: 1,
    stroke: "#151b22",
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

const underlineVariants = {
  hidden: {
    width: 0,
  },
  visible: {
    width: "100%",
    backgroundColor: "#d1d5dc",
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

const textVariants = {
  hidden: {
    y: 5,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.6,
    },
  },
};

interface Props {
  iconText: string;
  text: string;
  link: string;
}

export const IconLink: React.FC<Props> = ({ iconText, text, link }) => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className="icon-link">
      <svg width="51px" height="51px" viewBox="0 0 51 51" version="1.1">
        <motion.path
          d="
              M 22, 22
              m -21, 0
              a 21,21 0 1,0 42,0
              a 21,21 0 1,0 -42,0
              "
          strokeWidth={1}
          stroke="#151b22"
          fill="transparent"
          variants={pathVariants}
          initial="hidden"
          animate={isAppeared ? "visible" : "hidden"}
        ></motion.path>
      </svg>
      <motion.div
        className="icon-link-icon"
        variants={textVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      >
        {iconText}
      </motion.div>
      <motion.div
        variants={textVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      >
        <Link className="link-hover hover-orange" to={link}>
          {text}
          <ArrowRight className="icon-link-internal-icon" />
        </Link>
      </motion.div>

      <motion.div
        style={{ backgroundColor: "transparent", height: 1 }}
        variants={underlineVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      ></motion.div>
    </div>
  );
};
