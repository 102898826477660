import React from "react";
import { ReferenceType } from "../../store/reference/types";
import { Lead } from "../Lead";
import { ReferencesSlider } from "../ReferencesSlider";
import { ProcessInfoSection } from "../sections/ProcessInfoSection";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";

export const IndustryBuildings: React.FC = () => {
  return (
    <Page
      title="Průmyslové stavby – Stavíme průmyslové stavby v Pardubicích"
      description="Za posledních dvacet let jsme pro naše klienty jsme realizovali řadu projektů průmyslových areálů. Máme také zkušenosti s opravami stávajících objektů."
    >
      <AfterNavbar />
      <AppearSection threshold={0}>
        <Lead
          title="Stavíme průmyslové stavby"
          text="Pro naše klienty jsme realizovali řadu projektů průmyslových areálů. Ve většině případů šlo o stavby na klíč – od zajištění pozemků, přes zpracování projektové dokumentace, zajištění stavebního povolení a samotnou stavbu. Máme také zkušenosti s opravami stávajících objektů – od střech a fasád až po složité technologické vybavení vnitřních prostor."
        />
      </AppearSection>
      <ScrollTo />
      <div className="after-lead">
        <ReferencesSlider type={ReferenceType.Industry} appearThreshold={0} />
      </div>
      <ProcessInfoSection />
    </Page>
  );
};
