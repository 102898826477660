import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { getReferenceLink } from "../../store/reference/referenceService";

import { ReferenceDataWithoutDetail } from "../ReferenceData/ReferenceDataWithoutDetail";
import { GridContainer } from "../GridContainer";
import { GridSpace } from "../Spacing/GridSpace";
import { useCurrentReference } from "../../store/reference/useCurrentReference";
import { useReferences } from "../../store/reference/useReferences";
import {
  getNext,
  getPrev,
} from "../../store/reference/useCloseReferencesService";
import { AfterNavbar } from "../AfterNavbar";
import { GridSpaceSmall } from "../Spacing/GridSpaceSmall";
import { InfoBanner } from "../InfoBanner";
import { Quote, QuoteGrayBackground } from "../Quote";
import { Page } from "./Page";

import { ReferenceImage } from "../ReferenceImage";
import { AppearSection } from "../sections/AppearSection";
import { MotionArrowLeft } from "../Motion";
import { MotionArrowRight } from "../Motion";

export const ReferenceDetail: React.FC = () => {
  const reference = useCurrentReference();
  const references = useReferences(reference?.type);

  const prev = getPrev(reference, references);
  const next = getNext(reference, references);

  const [shouldAppear, setShouldAppear] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setShouldAppear(true);
    }, 300);
  }, [reference]);

  if (!reference) {
    return <GridSpace />;
  }

  return (
    <Page
      title="Realizace – Naše rodinné domy, rekonstrukce a průmyslové stavby"
      description="Poskytujeme širokou nabídku stavebních služeb. Stavíme originální, reprezentativní i typizované rodinné domy, realizujeme také rekonstrukce."
    >
      <AfterNavbar />
      <GridContainer>
        <div className="position-relative reference-swiper-navigation">
          <AppearSection>
            <div className="swiper-navigation">
              {prev && (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      history.push(getReferenceLink(prev.id));
                    }, 150);
                    setShouldAppear(false);
                  }}
                  to={getReferenceLink(prev.id)}
                >
                  <MotionArrowLeft />
                </Link>
              )}
              {next && (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                      history.push(getReferenceLink(next.id));
                    }, 150);
                    setShouldAppear(false);
                  }}
                  to={getReferenceLink(next.id)}
                >
                  <MotionArrowRight />
                </Link>
              )}
            </div>
          </AppearSection>
        </div>
        <div className="references-slider">
          <div className={classNames({ "swiper-slide-active": shouldAppear })}>
            <ReferenceDataWithoutDetail {...reference} />
          </div>
        </div>
      </GridContainer>
      <GridSpaceSmall />
      <div
        className={classNames({
          "swiper-slide-active": shouldAppear,
          "appear-images": true,
        })}
      >
        {reference.images.map((image, index) => {
          return (
            <div key={image}>
              <AppearSection threshold={10}>
                <div className="container-fluid">
                  <ReferenceImage src={image} />
                </div>
              </AppearSection>
              <GridSpaceSmall />
              {index === 3 && reference.quote && (
                <AppearSection>
                  <QuoteGrayBackground>
                    <Quote
                      author={reference.quote.author}
                      reference={reference.title}
                      quote={reference.quote.text}
                    />
                  </QuoteGrayBackground>
                  <GridSpaceSmall />
                </AppearSection>
              )}
            </div>
          );
        })}
      </div>
      <AppearSection>
        <InfoBanner />
      </AppearSection>

      <GridSpaceSmall />
    </Page>
  );
};
