import React from "react";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

const underlineVariants = {
  hidden: {
    width: 0,
  },
  visible: {
    width: "100%",
    backgroundColor: "#dedede",
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 1,
      delay: 0.6,
    },
  },
};

export const ReferencesDivider: React.FC = () => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className="references-divider">
      <motion.div
        style={{ backgroundColor: "transparent", height: 1 }}
        variants={underlineVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      ></motion.div>
    </div>
  );
};
