import React from "react";
import { motion } from "framer-motion";

import { useIsAppearedContext } from "../../context/IsAppearedContext";
import { TextImageText } from "../TextImage";

const textVariants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

export const TextImageTextDemand: React.FC = () => {
  const isAppeared = useIsAppearedContext();

  return (
    <TextImageText>
      <motion.div
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        variants={textVariants}
      >
        <h2>Až se poprvé společně potkáme.</h2>
        <p>
          Na první schůzce si společně projdeme dokumentaci a probereme Vaše
          přání a dotazy, abychom Vám dokonale porozuměli.
        </p>
        <p>
          Cenu Vaší stavby stanovíme po první schůzce, stejně jako harmonogram
          stavby.
        </p>
        <p>
          Když si spolu plácneme, podepíšeme smlouvu o dílo a stavba může začít.
          Váš projekt budeme průběžně kontrolovat a konzultovat s Vámi jeho
          průběh. Stavbu dotáhneme až k úspěšné kolaudaci.
        </p>
        <p>
          Svou poptávku včetně příloh posílejte na{" "}
          <a className="link-hover hover-orange" href="mailto:info@fastplay.cz">
            info@fastplay.cz
          </a>
        </p>
      </motion.div>
    </TextImageText>
  );
};
