import React from "react";
import { Helmet } from "react-helmet";
import ogImage from "../../images/og-image.png";

interface Props {
  title: string;
  description: string;
}

export const Page: React.FC<Props> = ({ children, title, description }) => {
  return (
    <div className="position-relative">
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:image" content={ogImage} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className="text-image-bg">
        <div className="container">
          <div className="bg-grid"></div>
        </div>
      </div>
      {children}
    </div>
  );
};
