import React from "react";
import { ReferenceType } from "../../store/reference/types";
import { Lead } from "../Lead";
import { ReferencesSlider } from "../ReferencesSlider";
import { ProcessInfoSection } from "../sections/ProcessInfoSection";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";

export const FamilyHouses: React.FC = () => {
  return (
    <Page
      title="Rodinné domy – Stavíme rodinné domy na klíč v Pardubicích"
      description="Specializujeme se na stavby domů na klíč od první konzultace až po kolaudaci. Rádi realizujeme odvážné a atypické domy od předních českých architektů."
    >
      <AfterNavbar />
      <AppearSection threshold={0}>
        <Lead
          title="Stavíme rodinné domy, ve kterých je radost bydlet"
          text="Specializujeme se na stavby domů na klíč od první konzultace až po kolaudaci. Rádi realizujeme odvážné a atypické domy od předních českých architektů. Všem našim projektům věnujeme maximální pozornost s důrazem na kvalitu provedení od základů do posledního detailu."
        />
      </AppearSection>
      <ScrollTo />
      <div className="after-lead">
        <ReferencesSlider
          type={ReferenceType.Family}
          appearThreshold={0}
          initialSlide={1}
        />
      </div>
      <ProcessInfoSection />
    </Page>
  );
};
