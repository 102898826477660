import { ReferenceType } from "./types";

export function getReferenceLink(id: string): string {
  return `/realizace/detail?stavba=${id}`;
}

export function getReferenceImageUrl(image: string): string {
  return `/images/references/${image}`;
}

const CLOSE_PAGE_MAPPING = {
  [ReferenceType.Family]: "/rodinne-domy",
  [ReferenceType.Reconstruction]: "/rekonstrukce",
  [ReferenceType.Industry]: "/prumyslove-stavby",
};

export function getReferenceClosePage(type?: ReferenceType): string {
  if (!type) {
    return "/realizace";
  }

  return CLOSE_PAGE_MAPPING[type];
}
