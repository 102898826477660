import React from "react";
import { motion } from "framer-motion";

import aboutImage1 from "../images/about-image-1.jpg";
import aboutImage2 from "../images/about-image-2.jpg";
import { useIsAppearedContext } from "../context/IsAppearedContext";

const imageVariants = {
  hidden: { x: "-100vw" },
  visible: {
    x: ["-100vw", "0vw", "0vw"],
    scale: [1, 1, 1.05],
    transition: {
      delay: 0.6,
      ease: [0.375, 0.725, 0, 1],
      duration: 1.2,
    },
  },
};

export function AboutImages() {
  const isAppeared = useIsAppearedContext();
  return (
    <div className="d-flex">
      <div className="responsive-div-image-wrapper about-image-left-wrapper">
        <motion.div
          initial="hidden"
          animate={isAppeared ? "visible" : "hidden"}
          variants={imageVariants}
          className="responsive-div-image background-right"
          style={{ backgroundImage: `url(${aboutImage1})` }}
        ></motion.div>
      </div>
      <div className="responsive-div-image-wrapper about-image-right-wrapper">
        <motion.div
          initial="hidden"
          animate={isAppeared ? "visible" : "hidden"}
          variants={imageVariants}
          className="responsive-div-image"
          style={{ backgroundImage: `url(${aboutImage2})` }}
        ></motion.div>
      </div>
    </div>
  );
}
