import React from "react";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../context/IsAppearedContext";

interface Props {
  title: string | React.ReactNode;
  text: string;
  link?: React.ReactNode;
}

const textVariants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 1,
      delay: 0.4,
    },
  },
};

const linkVariants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 1.8,
      delay: 0.4,
    },
  },
};

export const Description: React.FC<Props> = ({ title, text, link }) => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className="row">
      <div className="col-lg-4">
        <motion.h2
          variants={textVariants}
          animate={isAppeared ? "visible" : "hidden"}
          initial="hidden"
        >
          {title}
        </motion.h2>
      </div>

      <div className="col-lg-8">
        <motion.p
          variants={textVariants}
          animate={isAppeared ? "visible" : "hidden"}
          initial="hidden"
          className="description-text"
        >
          {text}
        </motion.p>
        {link && (
          <motion.div
            variants={linkVariants}
            animate={isAppeared ? "visible" : "hidden"}
            initial="hidden"
          >
            {link}
          </motion.div>
        )}
      </div>
    </div>
  );
};
