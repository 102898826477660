import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../../icons/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../icons/instagram-icon.svg";
import { Logo } from "../Navbar";

export const Footer: React.FC = () => {
  return (
    <footer className="bg-gray">
      <div className="container footer-content-wrapper">
        <div className="footer-section-logo">
          <Logo />
        </div>
        <div className="footer-section flex-grow-1">
          <div className="footer-section-heading">Služby</div>
          <div className="footer-section-links">
            <div>
              <Link className="link-hover" to="/rodinne-domy">
                Rodinné domy
              </Link>
            </div>
            <div>
              <Link className="link-hover" to="/rekonstrukce">
                Rekonstrukce
              </Link>
            </div>
            <div>
              <Link className="link-hover text-nowrap" to="/prumyslove-stavby">
                Průmyslové stavby
              </Link>
            </div>
            <div>
              <Link className="link-hover" to="">
                Bazény
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-section flex-grow-1">
          <div className="footer-section-heading">Kontakt</div>
          <div className="footer-section-links">
            <div>
              <a
                className="contact-link link-hover hover-orange text-nowrap"
                href="tel:+420702064615"
              >
                +420 702 064 615
              </a>
            </div>
            <div>
              <a
                className="contact-link link-hover hover-orange text-nowrap"
                href="mailto:info@fastplay.cz"
              >
                info@fastplay.cz
              </a>
            </div>
          </div>
        </div>
        <div className="footer-section flex-grow-1">
          <div className="footer-section-heading">Firma</div>
          <div className="footer-section-links">
            <div>
              <Link className="link-hover" to="/poptavka">
                Poptávka
              </Link>
            </div>
            <div>
              <Link className="link-hover" to="/realizace">
                Reference
              </Link>
            </div>
            <div>
              <Link className="link-hover" to="o-firme">
                O firmě
              </Link>
            </div>
            <div>
              <Link className="link-hover" to="/kontakt">
                Kontakt
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-section-heading">Kancelář</div>
          <div className="footer-section-links">
            <div>FastPlay s.r.o.</div>
            <div>Dašická 1796/8</div>
            <div>53003 Pardubice</div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container d-flex">
          <div className="social">
            <span className="d-none d-md-inline follow-us">Sledujte nás</span>
            <a href="#TODO" className="social-link">
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/fastplay.sro/"
              className="social-link"
            >
              <FacebookIcon />
            </a>
          </div>
          <div className="copy">
            <div className="link-list">
              © 2020 FastPlay・Všechna práva vyhrazena・
              <a href="#TODO">Zpracování dat</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
