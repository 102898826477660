import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import {
  getReferenceImageUrl,
  getReferenceLink,
} from "../store/reference/referenceService";
import { Reference } from "../store/reference/types";
import { ResponsiveImage } from "./ResponsiveImage";
import { useIsAppearedContext } from "../context/IsAppearedContext";

const imageVariants = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: ["-100vw", "0vw", "0vw"],
    scale: [1, 1, 1.05],
    transition: {
      delay: 0.6,
      ease: [0.375, 0.725, 0, 1],
      duration: 1.2,
    },
  },
};

interface Props {
  reference: Reference;
}

export const ReferenceSlide: React.FC<Props> = ({ reference }) => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className="container-fluid">
      <Link to={getReferenceLink(reference.id)}>
        <div className="overflow-hidden">
          <motion.div
            whileHover={{
              scale: 1,
            }}
            transition={{
              duration: 0.6,
              ease: [0.375, 0.725, 0, 1],
            }}
            initial="hidden"
            animate={isAppeared ? "visible" : "hidden"}
            variants={imageVariants}
          >
            <ResponsiveImage
              src={getReferenceImageUrl(reference.images[0])}
              alt={"referenceImage"}
            />
          </motion.div>
        </div>
      </Link>
    </div>
  );
};
