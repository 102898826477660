import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchReferencesFromApi } from "./referenceAPI";
import { Reference } from "./types";

interface ReferenceState {
  references: Reference[];
}

const initialState: ReferenceState = {
  references: [],
};

export const fetchReferences = createAsyncThunk("reference/fetch", async () => {
  const response = await fetchReferencesFromApi();
  return response;
});

export const {
  reducer: referenceReducer,
  actions: referenceActions,
} = createSlice({
  name: "reference",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReferences.fulfilled, (state, { payload }) => {
      state.references = payload;
    });
  },
});
