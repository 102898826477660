export function getImageKey(image: string | [string, string]): string {
  if (Array.isArray(image)) {
    return `${image[0]}${image[1]}`;
  }

  return image;
}

export function isDoubleImage(image: string | [string, string]): boolean {
  return Array.isArray(image);
}

export function getImageUrl(image: string): string {
  return `../../images/references/${image}`;
}

export function getImagesUrl(image: [string, string]): [string, string] {
  return [getImageUrl(image[0]), getImageUrl(image[1])];
}
