export const textVariants = {
  hidden: { y: 56 },
  visible: {
    y: 0,
    transition: {
      delay: 0.4,
      duration: 0.35,
    },
  },
};

export const quotesIconVariants = {
  hidden: { y: 56 },
  visible: {
    y: 0,
    transition: {
      delay: 0.4,
      duration: 0.35,
    },
  },
};
