import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Navbar, ReferenceNavbar } from "./components/Navbar";
import { Footer, ReferenceFooter } from "./components/Footer";

import { Homepage } from "./components/pages/Homepage";
import { FamilyHouses } from "./components/pages/FamilyHouses";
import { ScrollToTop } from "./components/ScrollToTop";
import { useDispatch } from "react-redux";
import { fetchReferences } from "./store/reference/referenceSlice";
import { IndustryBuildings } from "./components/pages/IndustryBuildings";
import { Reconstructions } from "./components/pages/Reconstructions";
import { References } from "./components/pages/References";
import { ReferenceDetail } from "./components/pages/ReferenceDetail";
import { AboutUs } from "./components/pages/AboutUs";
import { Demand } from "./components/pages/Demand";
import { Contact } from "./components/pages/Contact";
import { NotFound } from "./components/pages/NotFound";

export const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReferences());
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />

      <Switch>
        <Route path="/" exact>
          <Navbar />
          <Homepage />
          <Footer />
        </Route>
        <Route path="/rodinne-domy">
          <Navbar />
          <FamilyHouses />
          <Footer />
        </Route>
        <Route path="/prumyslove-stavby">
          <Navbar />
          <IndustryBuildings />
          <Footer />
        </Route>
        <Route path="/rekonstrukce">
          <Navbar />
          <Reconstructions />
          <Footer />
        </Route>
        <Route path="/realizace/detail">
          <ReferenceNavbar />
          <ReferenceDetail />
          <ReferenceFooter />
        </Route>
        <Route path="/realizace">
          <Navbar />
          <References />
          <Footer />
        </Route>
        <Route path="/o-firme">
          <Navbar />
          <AboutUs />
          <Footer />
        </Route>
        <Route path="/poptavka">
          <Navbar />
          <Demand />
          <Footer />
        </Route>
        <Route path="/kontakt">
          <Navbar />
          <Contact />
          <Footer />
        </Route>
        <Route>
          <Navbar />
          <NotFound />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
};
