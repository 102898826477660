import React from "react";
import { ReactComponent as FacebookIcon } from "../../icons/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../icons/instagram-icon.svg";

export const ReferenceFooter: React.FC = () => {
  return (
    <footer className="p-0">
      <div className="footer-bottom">
        <div className="container d-flex">
          <div className="social">
            <span className="d-none d-md-inline follow-us">Sledujte nás</span>
            <a href="#TODO" className="social-link">
              <InstagramIcon />
            </a>
            <a href="#TODO" className="social-link">
              <FacebookIcon />
            </a>
          </div>
          <div className="copy">
            <div className="link-list">
              © 2020 FastPlay・Všechna práva vyhrazena・
              <a href="#TODO">Zpracování dat</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
