import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../context/IsAppearedContext";

const pathVariants = {
  hidden: {
    pathLength: 0,
    fill: "none",
    stroke: "transparent",
  },
  visible: {
    pathLength: 1,
    stroke: "#151b22",
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

const textVariants = {
  hidden: {
    y: 5,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.6,
    },
  },
};
interface Props {
  icon?: React.ReactNode;
  title: string;
  text?: string;
  className?: string;
}

export const TextBox: React.FC<Props> = ({
  icon,
  title,
  text,
  children,
  className,
}) => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className={classNames("text-box position-relative", className)}>
      {icon ? (
        <>
          <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1">
            <motion.path
              d="
              M 30, 30
              m -29, 0
              a 29,29 0 1,0 58,0
              a 29,29 0 1,0 -58,0
              "
              strokeWidth={1}
              stroke="#151b22"
              fill="transparent"
              variants={pathVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
            ></motion.path>
          </svg>
          <motion.div
            variants={textVariants}
            initial="hidden"
            animate={isAppeared ? "visible" : "hidden"}
            className="position-absolute"
            style={{ left: 0, top: 0 }}
          >
            {icon}
          </motion.div>
        </>
      ) : (
        ""
      )}

      <motion.h3
        variants={textVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      >
        {title}
      </motion.h3>
      {text && (
        <motion.p
          variants={textVariants}
          initial="hidden"
          animate={isAppeared ? "visible" : "hidden"}
        >
          {text}
        </motion.p>
      )}
      {children}
    </div>
  );
};
