import React from "react";
import { Link } from "react-router-dom";

import { useCurrentReferences } from "../../store/reference/useCurrentReferences";
import {
  getReferenceImageUrl,
  getReferenceLink,
} from "../../store/reference/referenceService";
import { ReferenceData } from "../ReferenceData";
import { GridContainer } from "../GridContainer";
import { ReferencesFilter } from "../ReferencesFilter";
import { AfterNavbar } from "../AfterNavbar";
import { Page } from "./Page";
import { GridSpace } from "../Spacing/GridSpace";
import { AppearSection } from "../sections/AppearSection";
import { GridSpaceSmall } from "../Spacing/GridSpaceSmall";
import { ReferencesDivider } from "./ReferencesDivider";
import { MotionReferenceImage } from "../Motion";
import { Lead } from "../Lead";

export const References: React.FC = () => {
  const references = useCurrentReferences();

  return (
    <Page
      title="Realizace – Naše rodinné domy, rekonstrukce a průmyslové stavby"
      description="Poskytujeme širokou nabídku stavebních služeb. Stavíme originální, reprezentativní i typizované rodinné domy, realizujeme také rekonstrukce."
    >
      <AfterNavbar />
      <AppearSection threshold={0}>
        <Lead
          title="Naše realizace"
          hideScroll
          links={[
            <div key="filter" className="filter-links mb-5 mb-sm-0">
              <ReferencesFilter linkBase="/realizace" />
            </div>,
          ]}
        />
      </AppearSection>
      {references.map((reference, index) => {
        return (
          <div key={reference.id}>
            <AppearSection threshold={10}>
              <div className="container-fluid">
                <Link to={getReferenceLink(reference.id)}>
                  <MotionReferenceImage
                    src={getReferenceImageUrl(reference.images[0])}
                    alt={"referenceImage"}
                    delay={0.6}
                  />
                </Link>
              </div>
            </AppearSection>
            <AppearSection>
              <GridContainer className="single-reference">
                <ReferenceData
                  title={reference.title}
                  id={reference.id}
                  link={getReferenceLink(reference.id)}
                  useableArea={reference.useableArea}
                  landArea={reference.landArea}
                  duration={reference.duration}
                  architect={reference?.architect}
                />
                <ReferencesDivider />
              </GridContainer>
            </AppearSection>
            {index < references.length - 1 && <GridSpaceSmall />}
          </div>
        );
      })}
      <GridSpace />
    </Page>
  );
};
