import React from "react";
import { motion } from "framer-motion";

import { useIsAppearedContext } from "../../context/IsAppearedContext";
import { TextImageText } from "../TextImage";

const textVariants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

export const TextImageTextContact: React.FC = () => {
  const isAppeared = useIsAppearedContext();

  return (
    <TextImageText className="no-bg">
      <motion.div
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        variants={textVariants}
      >
        <h2>Ke každému klientovi přistupujeme individuálně.</h2>
        <p>
          Všechny požadavky a přání klienta se snažíme vždy splnit tak, aby byl
          stoprocentně spokojen, proto ho musíme nejprve poznat.
        </p>
        <p>
          Nejlépe se nad projektem debatuje nad šálkem kvalitní kávy. Proto Vás
          zveme do naší kanceláře v Pardubicích, kde můžeme vše společně projít
          a zodpovědět Vám veškeré otázky.
        </p>
        <h3>Petr Čejka</h3>
        <p>
          <a href="tel:+420702064615" className="link-hover hover-orange">
            +420 702 064 615
          </a>
          <br />
          <a href="mailto:info@fastplay.cz" className="link-hover hover-orange">
            info@fastplay.cz
          </a>
        </p>
      </motion.div>
    </TextImageText>
  );
};
