export interface Reference {
  id: string;
  title: string;
  type: ReferenceType;
  place?: string;
  useableArea: string;
  landArea: string;
  duration: string;
  architect?: string;
  images: string[];
  quote?: {
    text: string;
    author: string;
  };
}

export enum ReferenceType {
  Family = "family",
  Industry = "industry",
  Reconstruction = "reconstruction",
}
