import React from "react";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const TextImage: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames("container-fluid", "text-image", className)}>
      {children}
    </div>
  );
};
