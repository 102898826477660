import React from "react";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../context/IsAppearedContext";

const teamVariants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 0.8,
    },
  },
};

const members = [
  {
    name: "Petr Čejka",
    description: "Jednatel firmy",
    image: "/images/team/petr_cejka.jpg",
  },
  {
    name: "Ing. Markéta Rolinčinová",
    description: "Projekce staveb",
    image: "/images/team/marketa_rolincinova.jpg",
  },
  {
    name: "Petr Strasser",
    description: "Hlavní stavitel",
    image: "/images/team/petr_strasser.jpg",
  },
  {
    name: "Hugo Hrbek",
    description: "Příprava staveb",
    image: "/images/team/hugo_hrbek.jpg",
  },
];

export const Team: React.FC = () => {
  const membersToShow = members;
  const isAppeared = useIsAppearedContext();

  return (
    <>
      <div className="row team">
        {membersToShow.map((member) => {
          return (
            <motion.div
              variants={teamVariants}
              initial="hidden"
              animate={isAppeared ? "visible" : "hidden"}
              className="col-lg-4 col-sm-6 team-member"
              key={member.name}
            >
              <div className="team-member-image">
                <img src={member.image} alt={member.name} />
              </div>

              <h3>{member.name}</h3>
              <p>{member.description}</p>
            </motion.div>
          );
        })}
      </div>

      <p className="text-center mt-3 text-small text-primary">
        + další profese a odborníci
      </p>
    </>
  );
};
