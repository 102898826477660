import React from "react";
import { GridContainer } from "../GridContainer";
import { AfterNavbar } from "../AfterNavbar";
import { GridSpace } from "../Spacing/GridSpace";
import { Page } from "./Page";

export const NotFound: React.FC = () => {
  return (
    <Page
      title="Stavební firma Pardubice – Stavíme rodinné domy v Pardubicích"
      description="Ve stavebnictví podnikáme již více než dvacet let. Našim spokojeným klientům pomáháme s kompletní realizací moderních rodinných domů a rekonstrukcí."
    >
      <AfterNavbar />
      <GridContainer>
        <div className="lead">
          <h1>Nenalezeno</h1>
        </div>
      </GridContainer>
      <GridSpace />
    </Page>
  );
};
