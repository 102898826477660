import React from "react";
import { ReferenceType } from "../../store/reference/types";
import { Lead } from "../Lead";
import { ReferencesSlider } from "../ReferencesSlider";
import { ProcessInfoSection } from "../sections/ProcessInfoSection";
import { AfterNavbar } from "../AfterNavbar";
import { ScrollTo } from "../ScrollTo";
import { Page } from "./Page";
import { AppearSection } from "../sections/AppearSection";

export const Reconstructions: React.FC = () => {
  return (
    <Page
      title="Rekonstrukce – Realizujeme rekonstrukce domů a bytů v Pardubicích"
      description="Specializujeme se na kompletní rekonstrukce rodinných domů a bytů. Od průzkumu aktuálního stavu stavby až po případnou kolaudaci."
    >
      <AfterNavbar />
      <AppearSection>
        <Lead
          title="Provádíme rekonstrukce bytů a domů"
          text="Specializujeme se na kompletní rekonstrukce rodinných domů. Po průzkumu aktuálního stavu stavby zpracujeme na základě Vašich požadavků návrh, kompletní dokumentaci, potřebná povolení a realizaci stavby, až po případnou kolaudaci."
        />
      </AppearSection>
      <ScrollTo />
      <div className="after-lead">
        <ReferencesSlider
          type={ReferenceType.Reconstruction}
          appearThreshold={0}
        />
      </div>
      <ProcessInfoSection />
    </Page>
  );
};
