import React from "react";
import { Description } from "../Description";

interface Props {
  title?: string | React.ReactNode;
  text?: string;
  items: React.ReactNode[];
}

export const BoxList: React.FC<Props> = ({ title, text, items }) => {
  return (
    <>
      {text && title && <Description title={title} text={text} />}
      <div className="row box-list-items">{items}</div>
    </>
  );
};
