import React from "react";
import { motion } from "framer-motion";

import { useIsAppearedContext } from "../context/IsAppearedContext";

const backgroundVariants = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: "0",
    transition: {
      delay: 0.2,
      duration: 0.5,
      ease: [0.375, 0.725, 0, 1],
    },
  },
};

export const GrayBackground: React.FC = ({ children }) => {
  const isAppeared = useIsAppearedContext();
  return (
    <div className="overflow-hidden container-fluid">
      <motion.div
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        variants={backgroundVariants}
        className=" bg-gray section-spacing"
      >
        <div className="container">{children}</div>
      </motion.div>
    </div>
  );
};
