import animateScrollTo from "animated-scroll-to";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  const location = useLocation();

  return (
    <Link
      to="/"
      className="navbar-logo"
      onClick={() => {
        if (location.pathname === "/") {
          animateScrollTo(0, {
            maxDuration: 1000,
          });
        }
      }}
    >
      FASTPLAY
    </Link>
  );
};
