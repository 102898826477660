import React from "react";
import classNames from "classnames";

export interface ToggleNavbarButtonProps {
  toggleNavbar: any;
  isCollapsed: boolean;
}

export const ToggleNavbarButton: React.SFC<ToggleNavbarButtonProps> = ({
  toggleNavbar,
  isCollapsed,
}) => {
  const hamburgerClass = classNames({
    hamburger: true,
    "navbar-toggler": true,
    "hamburger--collapse": true,
    "is-active": !isCollapsed,
  });
  return (
    <button onClick={toggleNavbar} className={hamburgerClass} type="button">
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
};
