import React, { useEffect, useState } from "react";
import SwiperCore, {
  Navigation,
  Scrollbar,
  SwiperOptions,
  EffectFade,
  Autoplay,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { getReferenceLink } from "../store/reference/referenceService";
import { ReferenceType } from "../store/reference/types";
import { useReferences } from "../store/reference/useReferences";
import { ReferenceData } from "./ReferenceData";
import { GridContainer } from "./GridContainer";
import { MotionArrowLeft } from "./Motion";
import { MotionArrowRight } from "./Motion";
import { AppearSection } from "./sections/AppearSection";
import { ReferenceSlide } from "./ReferenceSlide";
import { SliderPagination } from "./SliderPagination";

SwiperCore.use([Scrollbar, Navigation, EffectFade, Autoplay, Pagination]);

const params: SwiperOptions = {
  spaceBetween: 0,
  slidesPerView: 1,
  loop: true,
  pagination: {
    clickable: false,
    type: "progressbar",
    el: ".swiper-pagination",
  },
  navigation: {
    nextEl: ".arrow-button-next",
    prevEl: ".arrow-button-prev",
  },
  autoplay: {
    delay: 5000,
  },
  effect: "fade",
};

interface Props {
  type?: ReferenceType;
  appearThreshold?: number;
  initialSlide?: number;
}

export const ReferencesSlider: React.FC<Props> = ({
  type,
  appearThreshold,
  initialSlide = 0,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    if (!swiper) {
      return;
    }

    swiper.update();
  }, [type, swiper]);

  const references = useReferences(type);

  if (references.length === 0) {
    return null;
  }

  return (
    <div className="references-slider">
      <Swiper {...params} onSwiper={setSwiper} initialSlide={initialSlide}>
        {references.map((reference) => {
          return (
            <SwiperSlide key={reference.id}>
              <AppearSection threshold={appearThreshold}>
                <ReferenceSlide reference={reference} />
              </AppearSection>
              <AppearSection>
                <GridContainer>
                  <ReferenceData
                    title={reference.title}
                    id={reference.id}
                    link={getReferenceLink(reference.id)}
                    useableArea={reference.useableArea}
                    landArea={reference.landArea}
                    duration={reference.duration}
                    architect={reference?.architect}
                  />
                </GridContainer>
              </AppearSection>
            </SwiperSlide>
          );
        })}

        <AppearSection threshold={appearThreshold}>
          <div className="container" style={{ paddingTop: "58px" }}>
            <div className="swiper-navigation">
              <MotionArrowLeft />
              <MotionArrowRight />
            </div>
            <SliderPagination />
          </div>
        </AppearSection>
      </Swiper>
    </div>
  );
};
