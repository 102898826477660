import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { ReferenceDataItem } from "./ReferenceDataItem";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

export interface ReferenceDataProps {
  id: string;
  title: string;
  link: string;
  useableArea: string;
  landArea: string;
  duration: string;
  architect?: string;
}

const textVariants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 0.6,
    },
  },
};

export const ReferenceData: React.SFC<ReferenceDataProps> = ({
  id,
  title,
  link,
  useableArea,
  landArea,
  duration,
  architect = "-",
}) => {
  const isAppeared = useIsAppearedContext();

  return (
    <motion.div
      variants={textVariants}
      initial="hidden"
      animate={isAppeared ? "visible" : "hidden"}
      className="reference-data"
      key={id}
    >
      <div className="reference-content-wrapper">
        <div className="title-spacing">
          <div className="appear-wrapper-h2">
            <Link to={link}>
              <h2 className="appear-content-h2 d-inline-block">{title}</h2>
            </Link>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <div className="appear-wrapper-property">
            <div className="appear-content-property">
              <Link to={link} className="link-hover hover-orange">
                Detail projektu
              </Link>
            </div>
          </div>

          <div className="reference-properties">
            <ReferenceDataItem value={useableArea} title="Plocha" />
            <ReferenceDataItem value={landArea} title="Pozemek" />
            <ReferenceDataItem value={duration} title="Výstavba" />
            <ReferenceDataItem value={architect} title="Architekt" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
