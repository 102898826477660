import React from "react";
import { motion } from "framer-motion";

import { getImageUrl } from "./ReferenceImageService";
import { MotionImage } from "./Motion";
import { useIsAppearedContext } from "../context/IsAppearedContext";

const separatorVariants = {
  hidden: { y: 30, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.6,
      ease: [0.375, 0.725, 0, 1],
      duration: 1.2,
    },
  },
};
interface Props {
  src: string | [string, string];
}

export const ReferenceImage: React.FC<Props> = ({ src }) => {
  const isAppeared = useIsAppearedContext();

  if (!Array.isArray(src)) {
    return <MotionImage src={getImageUrl(src)} alt={src} delay={0.6} />;
  }

  const [src1, src2] = src;
  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <MotionImage src={getImageUrl(src1)} alt={src1} delay={0.6} />
      </div>
      <motion.div
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        variants={separatorVariants}
        className="reference-image-separator flex-shrink-0"
      ></motion.div>
      <div className="flex-grow-1">
        <MotionImage src={getImageUrl(src2)} alt={src2} delay={0.6} />
      </div>
    </div>
  );
};
