import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useViewportScroll } from "framer-motion";
import { Link } from "react-router-dom";

import { ReactComponent as IconClose } from "../../icons/icon-close.svg";
import { useCurrentReference } from "../../store/reference/useCurrentReference";
import { getReferenceClosePage } from "../../store/reference/referenceService";
import { Logo } from "./Logo";

export const ReferenceNavbar: React.FC = () => {
  const reference = useCurrentReference();
  const closeLink = getReferenceClosePage(reference?.type);

  const { scrollY } = useViewportScroll();

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    scrollY.onChange((v) => {
      if (v > 0) {
        setIsScrolled(true);
        return;
      }
      setIsScrolled(false);
    });
  }, [scrollY]);

  return (
    <header>
      <div className="container">
        <nav className={classNames("navbar", { "navbar-compact": isScrolled })}>
          <div className="reference-navbar-logo">
            <Logo />
          </div>
          <div>
            <Link to={closeLink}>
              <IconClose />
            </Link>
            <Link
              to={closeLink}
              className="link-hover hover-orange ml-3 reference-navbar-close-link"
            >
              Zavřít realizace
            </Link>
          </div>
          <div className="navbar-collapse collapsed">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="mailto:info@fastplay.cz">
                  <span className="nav-hover">info@fastplay.cz</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="tel:+420702064615">
                  <span className="nav-hover">+420 702 064 615</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};
