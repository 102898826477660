import React from "react";
import { motion } from "framer-motion";

const pathVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 2.6,
    },
  },
};

export const MotionArrow: React.FC = () => {
  return (
    <svg width="51px" height="51px" viewBox="0 0 51 51" version="1.1">
      <title>Arrow</title>
      <g
        id="01.-Layouts-Web-(FastPlay)-[Ready]"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="FastPlay-–-Homepage-–-1680"
          transform="translate(-1458.000000, -544.000000)"
          stroke="#151B22"
        >
          <g id="01.-Hero-Texts" transform="translate(172.000000, 276.000000)">
            <g
              id="Arrow"
              transform="translate(1311.500000, 293.500000) scale(-1, 1) rotate(-270.000000) translate(-1311.500000, -293.500000) translate(1287.000000, 269.000000)"
            >
              <g transform="translate(12.000000, 14.000000)">
                <motion.path
                  d="M 5.46750145e-14,10.5300911 L 24.4138765,10.375"
                  strokeWidth={1}
                  stroke="#151b22"
                  fill="none"
                  variants={pathVariants}
                ></motion.path>
                <motion.path
                  d="M 14,-2.02473455e-13 L 25,10.5 L 14,21"
                  strokeWidth={1}
                  stroke="#151b22"
                  fill="none"
                  variants={pathVariants}
                ></motion.path>
              </g>
              <motion.path
                d="
              M 24, 24
              m -24, 0
              a 24,24 0 1,0 48,0
              a 24,24 0 1,0 -48,0
              "
                strokeWidth={1}
                stroke="#151b22"
                fill="none"
                variants={pathVariants}
              ></motion.path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
