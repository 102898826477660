import React from "react";
import { ReferenceDataItem } from "./ReferenceDataItem";

export interface ReferenceDataProps {
  id: string;
  title: string;
  useableArea: string;
  landArea: string;
  duration: string;
  architect?: string;
  place?: string;
}

export const ReferenceDataWithoutDetail: React.FC<ReferenceDataProps> = ({
  id,
  title,
  useableArea,
  landArea,
  duration,
  architect,
  place,
}) => {
  return (
    <div className="reference-data" key={id}>
      <div className="reference-content-wrapper">
        <div className="title-spacing">
          <div className="appear-wrapper-h2">
            <h2 className="appear-content-h2">{title}</h2>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row justify-content-between">
          {place ? (
            <ReferenceDataItem value={place} title="Místo" />
          ) : (
            <ReferenceDataItem value="-" title="Místo" />
          )}
          <div className="reference-properties">
            <ReferenceDataItem value={useableArea} title="Plocha" />
            <ReferenceDataItem value={landArea} title="Pozemek" />
            <ReferenceDataItem value={duration} title="Výstavba" />
            {architect ? (
              <ReferenceDataItem value={architect} title="Architekt" />
            ) : (
              <ReferenceDataItem value="-" title="Architekt" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
