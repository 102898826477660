import { useSelector } from "react-redux";
import { RootState } from "./rootReducer";
import { ReferenceType } from "./types";

export const useReferences = (type?: ReferenceType) => {
  return useSelector((state: RootState) => {
    if (!type) {
      return state.reference.references;
    }

    return state.reference.references.filter(
      (reference) => type === reference.type
    );
  });
};
