import React from "react";
import { motion } from "framer-motion";

import { ReactComponent as ArrowRight } from "../../icons/arrow-right-orange.svg";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

const underlineVariants = {
  hidden: {
    width: 0,
  },
  visible: {
    width: "100%",
    backgroundColor: "#d1d5dc",
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.35,
    },
  },
};

const textVariants = {
  hidden: {
    y: 5,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

interface Props {
  linkText: string;
  text: string;
  link: string;
}

export const ExternalLink: React.FC<Props> = ({ linkText, text, link }) => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className="icon-link">
      <motion.div
        variants={textVariants}
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
      >
        <a
          href={link}
          rel="noopener noreferrer"
          className="link-hover hover-orange"
          target="_blank"
        >
          {text}
          <ArrowRight className="icon-link-external-icon" />
        </a>

        <motion.div
          style={{ backgroundColor: "transparent", height: 1 }}
          variants={underlineVariants}
          initial="hidden"
          animate={isAppeared ? "visible" : "hidden"}
        ></motion.div>
        <div className="icon-link-after-text">{linkText}</div>
      </motion.div>
    </div>
  );
};
