import React from "react";
import classNames from "classnames";

interface Props {
  cols?: string;
}

export const BoxListItem: React.FC<Props> = ({ children, cols }) => {
  return (
    <div className={classNames({ "col-lg-4": !cols }, cols)}>{children}</div>
  );
};
