import React from "react";
import { motion } from "framer-motion";
import { useIsAppearedContext } from "../../context/IsAppearedContext";

interface Props {
  src: string;
}

const imageVariants = {
  hidden: { x: "-100vw" },
  visible: {
    x: ["-100vw", "0vw", "0vw"],
    scale: [1, 1, 1.05],
    transition: {
      delay: 1.2,
      ease: [0.375, 0.725, 0, 1],
      duration: 1.2,
    },
  },
};

export const TextImageImage: React.FC<Props> = ({ src }) => {
  const isAppeared = useIsAppearedContext();

  return (
    <div className="text-image-image-wrapper">
      <motion.div
        initial="hidden"
        animate={isAppeared ? "visible" : "hidden"}
        variants={imageVariants}
        className="text-image-image"
        style={{ backgroundImage: `url(${src})` }}
      ></motion.div>
    </div>
  );
};
