import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { useIsAppearedContext } from "../context/IsAppearedContext";

const textVariants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.375, 0.725, 0, 1],
      duration: 0.8,
      delay: 1.2,
    },
  },
};

const backgroundVariants = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: "0",
    transition: {
      delay: 0.6,
      duration: 0.8,
      ease: [0.375, 0.725, 0, 1],
    },
  },
};

export const InfoBanner: React.FC = () => {
  const isAppeared = useIsAppearedContext();
  return (
    <motion.div
      initial="hidden"
      animate={isAppeared ? "visible" : "hidden"}
      variants={backgroundVariants}
      className="container-fluid overflow-hidden"
    >
      <div className="bg-gray info-banner">
        <div className="container text-center">
          <motion.h2
            variants={textVariants}
            initial="hidden"
            animate={isAppeared ? "visible" : "hidden"}
          >
            Líbí se Vám náš přístup a naše realizace?
            <br />
            Plánujete stavbu vlastního domu?
          </motion.h2>
          <motion.p
            variants={textVariants}
            initial="hidden"
            animate={isAppeared ? "visible" : "hidden"}
          >
            Kontaktujte nás a domluvte si osobní schůzku
          </motion.p>
          <motion.div
            variants={textVariants}
            animate={isAppeared ? "visible" : "hidden"}
            initial="hidden"
          >
            <Link
              className="text-center link-hover hover-orange"
              to="/poptavka"
            >
              Poptávka
            </Link>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};
